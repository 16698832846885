import { FC, useContext, useEffect, useState } from "react";
import Blockly from "blockly/core";
import {
  BlocklyWorkspace,
  ToolboxDefinition,
  WorkspaceSvg,
} from "react-blockly";
// import Theme from "@blockly/theme-modern";
import { pythonGenerator } from "blockly/python";
import { IBlocklyProps } from "../types";
import workspaceConfiguration from "./workspaceConfiguration.json";
import { Screen } from "../helpers/Screen";
import { FileSystem } from "../helpers/FileSystem";
import cn from "classnames";

import * as Tr from "blockly/msg/tr";

const urlParams = new URLSearchParams(window.location.search);
var ApiUrl = process.env.REACT_APP_CDN_URL;
if (window.location.hostname.includes("editor-staging.riders.ai")) {
  ApiUrl = process.env.REACT_APP_STAGING_CDN_URL;
}

if (
  window.location.hostname.includes("localhost") &&
  urlParams.has("localcdn")
) {
  ApiUrl = "/cdn";
}

export const BEditor: FC<IBlocklyProps> = (props) => {
  const { className, setCode, setXML, project, file_path } = props;

  const isZelos = project?.blocklyTheme === "zelos";

  const { refreshKey } = useContext<any>(Screen.context);
  const { readFile } = useContext<any>(FileSystem.context);

  const [userXML, setUserXML] = useState<string | undefined>(undefined);

  const blocklyChangeEvent = new CustomEvent("blocklyChange", {
    detail: {
      message: "Blockly değişti",
      time: new Date(),
      // Diğer istediğiniz bilgiler...
    },
  });

  useEffect(() => {
    setUserXML(undefined);
    readFile(file_path).then((code: string) => {
      setUserXML(code);
    });
  }, [refreshKey]);

  const [toolboxCategories, setToolboxCategories] = useState<
    any | ToolboxDefinition
  >({
    kind: "categoryToolbox",
    contents: [],
  }); // Empty initial toolbox, if it will not find toolboxCategories.json

  const workspaceChange = (workspace: WorkspaceSvg) => {
    window.getBlocklyWorkspace = (CB) => {
      CB(workspace);
    };
    var code = pythonGenerator.workspaceToCode(workspace);
    if (project?.workspace.slice(1).includes("mars")) {
      // TODO (Etkin): Fix below
      code = `
from js import robot
from pyodide.ffi import to_js

import math

def set_wheel_speed(letter, value):
  robot.set_wheel_speed(to_js({'letter':letter, 'value':value}))

def set_drill(letter, value):
  robot.set_drill(to_js({'letter':letter, 'value':value}))

def set_light(letter, value):
  robot.set_light(to_js({'letter':letter, 'value':value}))

def set_robotic_angle(letter, value):
  robot.set_robotic_angle(to_js({'letter':letter, 'value':value}))

def shift_robotic_angle(letter, value):
  robot.shift_robotic_angle(to_js({'letter':letter, 'value':value}))

def set_strut_angle(letter, value):
  robot.set_strut_angle(to_js({'letter':letter, 'value':value}))

def is_map_visited(x, y):
  return robot.map_visited(to_js({'x':x, 'y':y}))

def get_delta_angle_to_tile(letter, x, y):
  return robot.get_delta_angle_to_tile(to_js({'letter':letter, 'x':x, 'y':y}))

def get_delta_angle_to_xy(letter, x, y):
  return robot.get_delta_angle_to_xy(to_js({'letter':letter, 'x':x, 'y':y}))

def get_distance_to_tile(letter, x, y):
  return robot.get_distance_to_tile(to_js({'letter':letter, 'x':x, 'y':y}))

def get_distance_to_xy(letter, x, y):
  return robot.get_distance_to_xy(to_js({'letter':letter, 'x':x, 'y':y}))

def get_key_state(index):
  return robot.get_key_state(to_js({'index':index}))

def blocking_sleep(seconds):
  robot.blocking_sleep(to_js({'seconds':seconds}))

def non_blocking_sleep(seconds):
  robot.non_blocking_sleep(to_js({'seconds':seconds}))

${code}`;

      code += `

scenario_${project.workspace.slice(1).charAt(4)}()
`;
    }
    setCode(code);
    try {
      const _panel: SVGAElement | null = document.querySelector(
        "#blockly_wrapper > div > div > svg:nth-child(7)"
      );
      if (_panel && _panel.style.display === "none")
        (document.querySelector(
          "#blockly_wrapper > div > div > svg:nth-child(8)"
        ) as SVGAElement)!.style.transform = "";
    } catch (ignored) {}
  };

  const onXmlChange = (newXML: string) => {
    setXML(newXML);
    window.dispatchEvent(blocklyChangeEvent);
  };

  const [scriptLoaded, setScriptLoaded] = useState(false);

  useEffect(() => {
    if (!project?.toolboxPath) return;
    fetch(`${ApiUrl}/${project.toolboxPath}`)
      .then((res) => res.json())
      .then((data) => {
        setToolboxCategories({
          kind: "categoryToolbox",
          contents: data.contents.map((item: any) => {
            return {
              ...item,
              categoryStyle:
                isZelos && !item.colour
                  ? item.name.toLowerCase() + "_category"
                  : false,
            };
          }),
        });
      });
  }, [project]);

  useEffect(() => {
    async function fetchBlockData(toolboxName: string) {
      try {
        // Make Blockly related modules available in the global scope
        const Blockly = await import("blockly");
        const BlocklyPython = await import("blockly/python");
        window.editorLanguage = project.editorLanguage;
        window.Blockly = Blockly;
        window.BlocklyPython = BlocklyPython.pythonGenerator;
        // Get BlockGenerator script
        const initializerURL = project.folderUrl + "initblocks.js";
        const generatorURL = project.folderUrl + "generateblocks.js";
        const scripts = [
          document.createElement("script"),
          document.createElement("script"),
        ];
        scripts[0].src = initializerURL;
        scripts[1].src = generatorURL;

        // Only first script's "onload" function defined
        scripts[0].onload = async () => {
          for (let i = 0; i < 40; i++) {
            if (window.blockly_generate_ready && window.blockly_init_ready) {
              setScriptLoaded(true);
              break;
            }
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
        };

        document.body.appendChild(scripts[0]);
        document.body.appendChild(scripts[1]);
      } catch (error) {
        console.error(error);
      }
    }
    if (!project?.toolboxName) return;
    fetchBlockData(project.toolboxName);
  }, [project]);

  useEffect(() => {
    if (window.blocklyThemeLoaded) return;

    if (project.editorLanguage === "TR") {
      Blockly.setLocale(Tr);
    }

    Blockly.Theme.defineTheme("riders", {
      name: "riders",
      base: isZelos ? Blockly.Themes.Zelos : Blockly.Themes.Classic,

      blockStyles: isZelos
        ? {
            math_blocks: {
              colourPrimary: "rgb(193, 70, 197)",
              colourSecondary: "rgb(187, 49, 189)",
              colourTertiary: "rgb(187, 49, 189)",
            },
          }
        : {},
      categoryStyles: {
        logic_category: {
          colour: "rgb(61, 128, 254)",
        },
        boolean_category: {
          colour: "rgb(61, 128, 254)",
        },
        loops_category: {
          colour: "rgb(29, 178, 121)",
        },
        math_category: {
          colour: "rgb(193, 70, 197)",
        },
        text_category: {
          colour: "rgb(253, 180, 8)",
        },
        lists_category: {
          colour: "rgb(134, 71, 254)",
        },
        robot_category: {
          colour: "rgb(111, 151, 74)",
        },
        variables_category: {
          colour: "rgb(209, 0, 35)",
        },
        kontrol_category: {
          colour: "rgb(209, 0, 35)",
        },
        motorlar_category: {
          colour: "rgb(209, 0, 35)",
        },
      },
      componentStyles: {
        workspaceBackgroundColour: isZelos ? "#eee" : "#1e1e1e",
        toolboxBackgroundColour: isZelos ? "#ccc" : "#333",
        toolboxForegroundColour: "#000",
        flyoutBackgroundColour: "#ddd",
        flyoutForegroundColour: "#000",
        flyoutOpacity: 0.75,
        scrollbarColour: "#1e1e1e",
        scrollbarOpacity: 0.6,
      },
      fontStyle: {},

      startHats: false,
    });

    window.blocklyThemeLoaded = true;
  }, []);

  if (!scriptLoaded || !project) {
    return (
      <div className={className} id="blockly_wrapper">
        <div className="h-full flex flex-col justify-center items-center">
          <span className="mb-6 text-2xl">Loading Block Coding</span>
          <div style={{ display: "flex" }}>
            <div className="dot dot--one"></div>
            <div className="dot dot--two"></div>
            <div className="dot dot--three"></div>
            <div className="dot dot--four"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn(className, {
        "zelos-theme": isZelos,
      })}
      id="blockly_wrapper"
      key={refreshKey}
    >
      {userXML && (
        <BlocklyWorkspace
          className="h-full"
          toolboxConfiguration={toolboxCategories}
          workspaceConfiguration={{
            media: workspaceConfiguration.media,
            renderer: isZelos ? "zelos" : "geras",
            zoom: {
              ...workspaceConfiguration.zoom,
              startScale: isZelos ? 0.75 : 0.9,
            },
            theme: "riders",
            grid: isZelos
              ? { spacing: 20, length: 3, colour: "#ccc", snap: true }
              : undefined,
            move: {
              scrollbars: {
                horizontal: true,
                vertical: true,
              },
              drag: true,
              wheel: true,
            },
          }}
          initialXml={userXML}
          onInject={workspaceChange}
          onWorkspaceChange={workspaceChange}
          onXmlChange={onXmlChange}
        />
      )}
    </div>
  );
};
