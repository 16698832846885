import React, { useEffect, useState, useContext } from "react";
import { IScreen } from "../types";
import { Auth } from "../../../contexts/ridersUserContext";

import { message, notification } from "antd";

import axios from "axios";

import * as git from "isomorphic-git";
import http from "isomorphic-git/http/web";
import FS from "@isomorphic-git/lightning-fs";
import { getCookie } from "../../shared/Cookie";
import * as path from "@isomorphic-git/lightning-fs/src/path";
import _, { forEach, set, debounce } from "lodash";
import { isInTimeMs } from "../../../utils";
import { publishBranch, storagePush } from "../../../api";
import { findPath } from "../../../utils/findPath";

export const FileContext = React.createContext({});

export const FileSystem: IScreen = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [notsApi, notsContextHolder] = notification.useNotification();
  const [clearStorage, setClearStorage] = useState<any>(false);
  const [inProcess, setInProcess] = useState(false);

  const {
    user,
    project,
    mode,
    setLoading,
    shutDown,
    setShutDown,
    accessTokenKey,
    userCodeFilePath,
  } = useContext<any>(Auth.context);
  const [isFileSystemReady, setFileSystemReady] = useState(false);
  const [lastCommitHash, setLastCommitHash] =
    useState<string>("git-not-available");

  let LFS = new FS(
    project.storage !== "none"
      ? `${user.userFrom}_fs${mode === "preview" ? "_preview" : ""}`
      : `${user.username || user.id}${project.workspace}`
  );
  let fs = LFS.promises;
  window.pfs = fs;

  const base_path =
    user.userFrom === "riders"
      ? `/${user.id}/${project.session_id}`
      : user.userFrom === "deneyap"
      ? `/${user.id}/${project.project_id}`
      : `/`;

  useEffect(() => {
    if (userCodeFilePath === "" || userCodeFilePath === undefined) {
      return;
    }
    if (project.dev === "none") {
      setFileSystemReady(true);
      window.fileSystemReady = true;
      return;
    }
    (async () => {
      try {
        if (project.storage === "none") {
          git_init();
        }

        if (project.storage !== "none" && user.id) {
          // Flush base bath except for the current user.
          await deleteExceptEntries("/", [user.id.toString()]);

          if (mode === "preview") {
            // Flush records older than 3hours (For a single user only?)
            await deleteExpiredEntries(`/${user.id}/`, 1000 * 60 * 60 * 3);
          } else {
            // Flush all data but keep still active sessions
            if (user.userFrom === "riders") {
              await deleteExceptEntries(
                `/${user.id}/`,
                project.activeSessionList
              );
            }
            await deleteExpiredEntries(`/${user.id}/`, 1000 * 60 * 60 * 3);
          }

          if (project.storage === "git") {
            // Clone single branch from git address

            console.log("Git Address: " + project.git_address);
            await git_clone(project.git_address, project.git_branch);
            console.log("Git Cloned: " + new Date().toLocaleString());

            // Set commitHash
            const hash = await git_get_last_commit_hash();
            setLastCommitHash(hash);
          } else {
            await git_init();
          }

          for (const file of project.files) {
            if (typeof file === "string") {
              try {
                const response = await axios.get(
                  project.storage_address + "/" + encodeURIComponent(file)
                );
                const isObject = _.isObject(response.data);
                if ((window as any).mode === "debug") {
                  console.log(
                    "Fetch File: " + findPath(file),
                    response.headers["content-type"],
                    isObject ? "success" : "fail"
                  );
                }
                const content = isObject
                  ? JSON.stringify(response.data)
                  : response.data;
                await writeFile(findPath(file), content);
              } catch (error) {
                await writeFile(findPath(file), "");
              }
            }
          }

          if (project.storage === "s3") {
            git_add("/");
            const sha = await git_commit("auto commit by riders");
            setLastCommitHash(sha);
          }
        }

        await makeDir("/tmp");

        console.log("File System Ready: " + userCodeFilePath);

        await writeFile(userCodeFilePath, project.defaultCode, false);

        if (project.dev === "blockly") {
          await writeFile("/blockly.xml", project.defaultXML, false);
        }

        window.fileSystemReady = true;
        setFileSystemReady(true);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [userCodeFilePath]);

  const debounceGitSyncHandler = debounce(async () => {
    if (project.dev === "blockly") {
      await gitSyncHandler(["usercode", "blockly.xml"], true);
    }
    if (project.dev === "code") {
      await gitSyncHandler(["usercode"], true);
    }
  }, 5000);

  const leaveHandler = async (event: any) => {
    if (inProcess) {
      debounceGitSyncHandler.cancel();
      event.preventDefault();
      event.returnValue = "Değişikliklerinizi kaydettiniz mi?";

      messageApi.open({
        key: "leave",
        type: "loading",
        content: "Your changes are being saved",
        duration: 3,
        className: "messagebox",
      });

      if (project.dev === "blockly") {
        await gitSyncHandler(["usercode", "blockly.xml"], true);
      }
      if (project.dev === "code") {
        await gitSyncHandler(["usercode"], true);
      }

      messageApi.open({
        key: "leave",
        type: "success",
        content: "Your changes are saved",
        duration: 3,
        className: "messagebox",
      });

      return event.returnValue;
    }
  };

  useEffect(() => {
    if (isFileSystemReady === false) {
      return;
    }
    if (Boolean(shutDown) || mode !== "editor" || project.dev === "none") {
      return;
    }
    if (inProcess) {
      debounceGitSyncHandler();

      window.addEventListener("beforeunload", leaveHandler);

      if (project.dev === "blockly") {
        window.addEventListener("blocklyChange", debounceGitSyncHandler);
      } else {
        window.addEventListener("keypress", debounceGitSyncHandler);
      }
      return () => {
        window.removeEventListener("keypress", debounceGitSyncHandler);
        window?.removeEventListener("blocklyChange", debounceGitSyncHandler);
        window.removeEventListener("beforeunload", leaveHandler);
      };
    }
  }, [isFileSystemReady, shutDown, mode, project, lastCommitHash, inProcess]);

  useEffect(() => {
    isFileSystemReady && setLoading(false);
  }, [isFileSystemReady]);

  const pathGenerator = (path: string) => {
    path === "/" && (path = "");
    return path.indexOf(base_path) === 0
      ? path
      : `${base_path}${path.indexOf("/") === 0 ? "" : "/"}${path}`;
  };

  const getBasePath: () => string = () => {
    return base_path;
  };

  const getFullPath: (param1: string) => string = (path: string) => {
    return pathGenerator(path);
  };

  const makeDir = async (directory: string) => {
    const dirPath = pathGenerator(directory);
    return new Promise<string>(async (resolve, reject) => {
      const parentPath = path.dirname(dirPath);
      try {
        await LFS.promises.stat(parentPath);
      } catch (err: any) {
        if (err.code === "ENOENT") {
          try {
            await makeDir(parentPath);
          } catch (err) {
            reject(err);
            return;
          }
        } else {
          reject(err);
          return;
        }
      }

      try {
        await LFS.promises.stat(dirPath);
        resolve(dirPath);
      } catch (err: any) {
        if (err.code === "ENOENT") {
          try {
            await LFS.promises.mkdir(dirPath);
            resolve(dirPath);
          } catch (err) {
            reject(err);
          }
        } else {
          reject(err);
        }
      }
    });
  };

  const writeFile = async (
    filepath: string,
    content: string,
    rewrite = true,
    addtogit = true
  ) => {
    let filePath = pathGenerator(filepath);

    if (window.fileSystemReady) {
      setInProcess(true);
    }

    return new Promise<boolean>(async (resolve, reject) => {
      try {
        const parentPath = path.dirname(filePath);
        await makeDir(parentPath);

        if (!rewrite) {
          try {
            await LFS.promises.stat(filePath);
            resolve(false);
            return;
          } catch (err: any) {
            if (err.code !== "ENOENT") {
              reject(err);
            }
          }
        }

        LFS.writeFile(filePath, content, "utf8", (err) => {
          if (err) {
            reject(err);
          } else {
            resolve(true);
          }
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  const readFile = async (filepath: string) => {
    const filePath = pathGenerator(filepath);
    return new Promise<string | null>(async (resolve, reject) => {
      try {
        const data = (await LFS.promises.readFile(filePath, "utf8")) as string;
        resolve(data);
      } catch (error: any) {
        if (error.code !== "ENOENT") {
          reject(error);
          return;
        } else {
          resolve(null);
        }
      }
    });
  };

  const readDir = async (filepath: string) => {
    const filePath = pathGenerator(filepath);
    return new Promise<string[] | null>(async (resolve, reject) => {
      try {
        const data = (await LFS.promises.readdir(filePath)) as string[];
        resolve(data);
      } catch (error: any) {
        if (error.code !== "ENOENT") {
          reject(error);
          return;
        } else {
          resolve(null);
        }
      }
    });
  };

  const updateFile = async (path: string, content: string) => {
    if (window.fileSystemReady) {
      setInProcess(true);
    }

    const filePath = pathGenerator(path);
    return new Promise<string>(async (resolve, reject) => {
      try {
        await LFS.promises.du(filePath);
        await LFS.promises.writeFile(filePath, content, "utf8");
      } catch (error) {
        reject(error);
      }
    });
  };

  const deleteFile = async (path: string) => {
    const filePath = pathGenerator(path);
    return new Promise<null>(async (resolve, reject) => {
      try {
        await LFS.promises.unlink(filePath);
        await git_remove(path);
        resolve(null);
      } catch (err) {
        reject(err);
      }
    });
  };

  const deleteExceptEntries = async (path: string, except: string[]) => {
    const folderPath = path;
    return new Promise<null>(async (resolve, reject) => {
      try {
        const entries = await LFS.promises.readdir(path);
        for (const entry of entries) {
          if (!except.includes(entry)) {
            await LFS.promises.unlink(folderPath + entry);
          }
        }
        resolve(null);
      } catch (error: any) {
        if (error.code !== "ENOENT") {
          reject(error);
          return;
        } else {
          resolve(null);
        }
      }
    });
  };

  const deleteExpiredEntries = async (path: string, expired: number) => {
    const folderPath = path;
    return new Promise<null>(async (resolve, reject) => {
      try {
        const entries = await LFS.promises.readdir(path);
        for (const entry of entries) {
          const { ctimeMs } = await LFS.promises.stat(folderPath + entry);
          if (!isInTimeMs(ctimeMs, expired)) {
            await LFS.promises.unlink(folderPath + entry);
          }
        }
        resolve(null);
      } catch (error: any) {
        if (error.code !== "ENOENT") {
          reject(error);
          return;
        } else {
          resolve(null);
        }
      }
    });
  };

  const git_init = async () => {
    return new Promise<null>(async (resolve, reject) => {
      try {
        await git.init({
          fs: LFS,
          dir: base_path,
        });
        resolve(null);
      } catch (err) {
        reject(err);
      }
    });
  };

  const git_status = async (path: string | string[]) => {
    if (typeof path !== "string") {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const status = await git.statusMatrix({
            fs: LFS,
            dir: base_path,
            filepaths: path,
          });

          let statusString = "unmodified";
          let modifiedFiles: any[] = [];

          forEach(status, (item) => {
            if (item[1] !== 1 || item[2] !== 1 || item[3] !== 1) {
              statusString = "modified";
              modifiedFiles.push(item[0]);
            }
          });

          if (modifiedFiles.length > 0) {
            statusString = `modified`;
          }

          resolve({
            status: statusString,
            files: modifiedFiles,
          });
        } catch (err) {
          reject(err);
        }
      });
    }

    const filePath = _.replace(pathGenerator(path), base_path + "/", "");

    return new Promise<string>(async (resolve, reject) => {
      try {
        const status = await git.status({
          fs: LFS,
          dir: base_path,
          filepath: filePath,
        });
        resolve(status);
      } catch (err) {
        reject(err);
      }
    });
  };

  const git_remove = async (path: string) => {
    const filePath = _.replace(pathGenerator(path), base_path + "/", "");
    return new Promise<null>(async (resolve, reject) => {
      try {
        await git.remove({
          fs: LFS,
          dir: base_path,
          filepath: filePath,
        });
        resolve(null);
      } catch (err) {
        reject(err);
      }
    });
  };

  const git_add = async (path: string) => {
    const filePath = _.replace(pathGenerator(path), base_path + "/", "");
    return new Promise<null>(async (resolve, reject) => {
      const gitignore = await readFile(".gitignore");
      if (gitignore) {
        _.map(gitignore.split("\n"), (item) => {
          if (item) {
            _.startsWith(filePath, item) && resolve(null);
            return;
          }
        });
      }
      if (filePath === "") {
        const entries = await LFS.promises.readdir(base_path);
        for (const entry of entries) {
          git_add(entry);
        }
        resolve(null);
      } else {
        try {
          await git.add({
            fs: LFS,
            dir: base_path,
            filepath: filePath,
          });
          resolve(null);
        } catch (err) {
          reject(err);
        }
      }
    });
  };

  const git_clone = async (url: string, ref?: string) => {
    return new Promise<null>(async (resolve, reject) => {
      try {
        console.log("git clone", base_path);
        const stats = await LFS.promises.stat(base_path);
        if (stats.isDirectory()) {
          LFS.promises.unlink(base_path);
        }
      } catch (err) {}
      try {
        console.log("git clone", url);
        await git.clone({
          fs: LFS,
          http,
          dir: base_path,
          //corsProxy: (activeProxyServerAddress as string) || activeProxyServer,
          url,
          singleBranch: true,
          ref,
          depth: 1,
        });
        resolve(null);
      } catch (error: any) {
        reject(error);
      }
    });
  };

  type gitUser = {
    name: string;
    email: string;
  };
  const git_commit = async (message: string, gitUser?: gitUser) => {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const sha = await git.commit({
          fs: LFS,
          dir: base_path,
          message,
          author: {
            name: gitUser?.name || "riders",
            email: gitUser?.email || "riders@riders.ai",
          },
        });
        resolve(sha);
      } catch (error) {
        reject(error);
      }
    });
  };

  const git_push = async (files: string[]) => {
    if (project.storage === "none" || user.id === undefined) {
      return;
    }

    if (project.storage === "s3") {
      return new Promise<null>(async (resolve, reject) => {
        try {
          const changes = [];

          for (const file of files) {
            const content = await readFile(file);
            const promis = storagePush(
              getCookie(accessTokenKey) as string,
              file,
              content
            );
            changes.push(promis);
          }

          await Promise.all(changes);
          resolve(null);
        } catch (error) {
          reject(error);
        }
      });
    }

    return new Promise<null>(async (resolve, reject) => {
      try {
        await git.push({
          fs: LFS,
          http,
          dir: base_path,
          remote: "origin",
          //corsProxy: activeProxyServer,
        });
        resolve(null);
      } catch (error) {
        reject(error);
      }
    });
  };

  const git_add_commit_push = async () => {
    return new Promise<string>(async (resolve, reject) => {
      try {
        await git_add("/");
        const sha = await git_commit("auto commit by riders");
        await git_push([]);
        resolve(sha);
      } catch (error: any) {
        reject(error);
      }
    });
  };

  const git_get_last_commit_hash = async () => {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const sha = await git.resolveRef({
          fs: LFS,
          dir: base_path,
          ref: "HEAD",
        });
        resolve(sha);
      } catch (error: any) {
        reject(error);
      }
    });
  };

  //todo rewrite
  const publish_branch = async (message: string) => {
    messageApi.open({
      key: "publish",
      type: "loading",
      content: "Branch is being published",
      duration: 60,
      className: "messagebox",
    });

    try {
      await publishBranch(getCookie(accessTokenKey) as string, message);
      messageApi.open({
        key: "publish",
        type: "success",
        content: "Branch is published",
        duration: 2,
        className: "messagebox",
      });
    } catch (error) {
      messageApi.open({
        key: "publish",
        type: "error",
        content: "Branch is not published",
        duration: 2,
        className: "messagebox",
      });
    }
  };

  const gitSyncHandler = async (
    folders: string[],
    withPush: boolean = false
  ) => {
    return new Promise<any>(async (resolve, reject) => {
      const { status, files } = await git_status(folders);

      if (status !== "unmodified") {
        await git_add("/");
        const sha = await git_commit("auto commit by riders");

        if (withPush && project.storage !== "none") {
          await git_push(files);
        }

        setLastCommitHash(sha);

        setInProcess(false);
        console.log("Git Synced: " + new Date().toLocaleString());

        resolve({
          status: "modified",
          hash: sha,
          files: files,
        });
      } else {
        setInProcess(false);
        resolve({
          status: "unmodified",
          hash: lastCommitHash,
          files: [],
        });
      }
    });
  };

  useEffect(() => {
    window.fs = {
      list: readDir,
      readFile: readFile,
      writeFile: writeFile,
      updateFile: updateFile,
      deleteFile: deleteFile,
      makeDir: makeDir,
      getFullPath: getFullPath,
      getBasePath: getBasePath,
      clearStorage: clearStorage,
      setClearStorage: setClearStorage,
    };

    window.git = {
      add: git_add,
      commit: git_commit,
      push: git_push,
      add_commit_push: git_add_commit_push,
      clone: git_clone,
      status: git_status,
      remove: git_remove,
      get_last_commit_hash: git_get_last_commit_hash,
    };
  }, []);

  return (
    <FileContext.Provider
      value={{
        readFile,
        readDir,
        writeFile,
        updateFile,
        deleteFile,
        git_status,
        git_clone,
        git_get_last_commit_hash,
        git_remove,
        git_add,
        git_commit,
        publish_branch,
        isFileSystemReady,
        getFullPath,
        getBasePath,
        clearStorage,
        setClearStorage,
        lastCommitHash,
        setLastCommitHash,
        git_push,
        gitSyncHandler,
        inProcess,
        debounceGitSyncHandler,
      }}
    >
      {contextHolder}
      {notsContextHolder}
      {children}
    </FileContext.Provider>
  );
};

FileSystem.context = FileContext;
